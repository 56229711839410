import { getArea } from '@/api';
import store from '@/store';

//const blogArticleCategory = (store.state.data.all as any)?.blogArticleCategory;

//console.log(blogArticleCategory);

interface CategoryT {
	onlyid: string | number;
	title: string;
	description: string;
	image: string;
	children?: CategoryT[];
}

type CategoryL = CategoryT[];
interface Res {
	key: string | number;
	title: string;
	description: string;
}

const getCategory = (name: string) => {
	return new Promise((resolve, reject) => {
		(getArea('BLOG_ARTICLE_CATEGORY') as any)
			.then((data: CategoryL) => {
				//console.log('获取到的分类信息', data);
				const res: Res[] = [];
				data?.forEach((item: CategoryT) => {
					if (item.onlyid === name) {
						res.push({
							key: item.onlyid,
							title: item.title,
							description: item.description,
						});
						return;
					}
					if (item.children) {
						const find_ = item.children.find((item0: CategoryT) => item0.onlyid === name);
						if (find_) {
							res.push({
								key: item.onlyid,
								title: item.title,
								description: item.description,
							});
							res.push({
								key: find_.onlyid,
								title: find_.title,
								description: find_.description,
							});
						}
						return;
					}
				});
				resolve(res);
				/*
				const find = data?.find((item: CategoryT) => {
					let find_: any;
					const res_ = item.onlyid === name;
                    //如果在一级找到，则直接给res.push
                    if(res_){
                        res.push({
                            key:res_
                        })
                    }
					if (!res_ && item.children) {
						find_ = item.children.find(item_ => item_.onlyid === name);
						if (find_) {
							j = 1;
						}
					}
					if (res_ || find_) {
						return true;
					}
				});
				resolve(res);
                */
				/*
				const res = [];
				const find = data?.find((item: any) => item?.onlyid === name);
				//如果没有找到，则到下一级去找
				res.push({
					key: find.onlyid,
					title: find.title,
					description: find.description,
				});
				resolve(res);
                */
			})
			.catch((error: any) => {
				reject(name);
			});
	});
	/*
    if (
        typeof blogArticleCategory === 'object' &&
        Array.isArray(blogArticleCategory)
    ) {
        const find = blogArticleCategory.find(item => item.onlyid === name);
        return (find as any)?.title || name;
    } else {
        return name;
    }
    */
};
export default getCategory;
