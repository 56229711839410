import Vue from 'vue';
import { defineComponent, ref, reactive, toRefs, watch, nextTick } from '@vue/composition-api';
import type { Ref, Data } from '@vue/composition-api';

import store from '@/store';

import textfill from './textfill';
import getCategory from './get-category';
import ImgLazyLoad from './img-lazy-load';

import Card from '@/components/Card';

import CommentList from '@/components/Article/CommentList';
import CommentPublish from '@/components/Article/CommentPublish';

import { req } from '@/api';

import { Skeleton, Btn } from '@/components/UI';

import { generateOnlyid, parseTime } from '@/public/methods';

import FetchData from '@/components/FetchData';

import SvgBook from '@/svg/book';
import SvgThumbUp from '@/svg/thumb-up';
import SvgCommit from '@/svg/commit';
import SvgArrow from '@/svg/arrow';

import hljs from 'highlight.js';
import 'highlight.js/styles/atelier-heath-light.css';
import pub from '@/public/pub';
//const highlightJs = import('hightlight.js');
//const hljs = import('highlight.js');

interface Anchor {
	onlyid: string;
	text: string;
	tagName: string;
}

export default defineComponent({
	components: { Card, Skeleton, CommentList, CommentPublish, Btn, SvgBook, SvgCommit, SvgThumbUp, SvgArrow, FetchData, ImgLazyLoad },
	directives: {
		spec(el) {
			el.querySelectorAll('a').forEach(a => {
				a.setAttribute('target', '_blank');
			});
			el.querySelectorAll('p').forEach(p => {
				if (p.firstElementChild?.tagName === 'IMG') {
					p.setAttribute('data-only-image', 'true');
				}
			});
			el.querySelectorAll('img').forEach(imgElement => {
				imgElement.setAttribute('preview', '0');
				imgElement.setAttribute('v-lazy', imgElement.getAttribute('src') || '');
				//imgElement.style.display = 'none';
				const showImgElement = document.createElement('div');
				if (imgElement.getAttribute('src')) {
					showImgElement.innerHTML = imgElement.getAttribute('src')!;
					showImgElement.setAttribute('data-lazy-load-img-controller', 'true');
					showImgElement.style.display = 'none';
					if (imgElement.previousElementSibling && imgElement.previousElementSibling.getAttribute('data-lazy-load-img-controller')) {
						//不添加
					} else {
						//添加
						imgElement.parentElement?.insertBefore(showImgElement, imgElement);
					}
				}
			});
		},
		highlight(el) {
			el.querySelectorAll('pre code').forEach(block => {
				(hljs as any).highlightBlock(block as HTMLElement);
			});
		},
		fetchmenu(el) {
			const anchorArr: Anchor[] = [];
			el.querySelectorAll('h1,h2,h3,h4,h5,h6').forEach((elItem: Element) => {
				const onlyid = `article_anchor_${generateOnlyid()}`;
				elItem.setAttribute('name', onlyid);
				anchorArr.push({
					onlyid,
					text: elItem.innerHTML,
					tagName: ('' + elItem.tagName).toLowerCase(),
				});
			});
			pub.set('articleAnchorArr', anchorArr);
		},
	},

	setup(props: any, { root }: any) {
		const { $message, $modal, $route, $previewRefresh } = root;
		const t = root.$t.bind(root);
		//获取文章数据
		const isLoading: Ref<boolean> = ref(true);
		const isError: Ref<boolean> = ref(false);
		const errorMsg: Ref<string> = ref('');
		const data: Ref<any> = ref({});
		const { onlyid } = $route?.params || {};
		function startReq() {
			if (pub.get(`article-data-${onlyid}`) && typeof $route.query.commentPage !== 'undefined') {
				data.value = pub.get(`article-data-${onlyid}`);
				isLoading.value = false;
				return;
			}
			req('BLOG_ARTICLE_ARTICLE', {
				onlyid,
			})
				.then(data1 => {
					data.value = data1;
					//console.log('路由', $route);
					//console.log(data1);
					data.value.title &&
						store.commit('changeMetaInfo', {
							title: data.value.title + ' - ' + store.state.metaInfo.title,
						});
					//临时缓存，以备刷新评论列表
					pub.set(`article-data-${onlyid}`, data1);
					//刷新图片列表
					$previewRefresh();
				})
				.catch(error => {
					$message.error(error);
					isError.value = true;
					errorMsg.value = error;
				})
				.finally(() => {
					isLoading.value = false;
				});
		}
		startReq();
		//监听字体大小
		const fontSize: Ref<string> = ref('normal');
		watch(
			() => store.state.articleFontSize,
			value => {
				fontSize.value = value;
			},
			{ immediate: true }
		);
		//监听分类名称
		const categoryName: Ref = ref([
			{
				key: '',
				title: '',
				description: '',
			},
		]);
		watch(
			() => data.value,
			value => {
				if (value?.category) {
					getCategory(value?.category)
						.then(value => {
							categoryName.value = value;
						})
						.catch(() => (categoryName.value = value.category));
				}
			},
			{ deep: true }
		);
		//监听文章的上一篇下一篇
		const articleNeighborData: Ref<any> = ref({
			later: null,
			earlier: null,
		});
		const touchArticleNeighborData = (data: any) => {
			articleNeighborData.value = data;
		};
		//文章阅读、点赞、评论、分享功能
		const bpCountMethods = {
			read: () => {
				$modal(() => '无法查看他人的阅读信息');
			},
			primary: () => {
				data.value.praiseCount += 1;
				if (!isLoading.value && !isError.value) {
					req('SEND_LIKE', {
						tribe: 'article',
						onlyid,
					})
						.then(() => {
							$message.success('点赞成功，感谢有你陪伴！');
						})
						.catch(error => {
							//$message.warning('点赞失败:' + error);
							$message.warning('' + error);
							data.value.praiseCount -= 1;
						});
				}
			},
			comment: () => {
				try {
					//document.documentElement.scrollTo(0, 9999);
					const cpfEl = document.querySelector('[data-comment-publish-form-area]');
					if (cpfEl) {
						const top = cpfEl.getBoundingClientRect().y + document.documentElement.scrollTop;
						document.documentElement.scrollTo(0, top);
					}
				} catch (e) {
					//
				}
				try {
					const commentEl = document.querySelector('[data-draft-wysiwyg-container]');
					if (commentEl) {
						const activeEl = commentEl.childNodes[0].childNodes[1];
						//console.log(activeEl);
						(activeEl as any).onclick();
					}
				} catch (e) {
					//
				}
			},
			share: () => {
				$modal(() => '暂不支持分享');
			},
		};
		return {
			//...toRefs(data),
			data,
			isLoading,
			isError,
			errorMsg,
			fontSize,
			parseTime,
			textfill,
			//getCategory,
			categoryName,
			t,
			articleNeighborData,
			touchArticleNeighborData,
			bpCountMethods,
			errorEventThings(thing: string) {
				switch (thing) {
					case 'reload':
						isError.value = false;
						isLoading.value = true;
						startReq();
						break;
					case 'reloadPage':
						window.location.reload();
						break;
					case 'back':
						window.history.back();
						break;
					default:
						alert('err:' + thing);
				}
			},
		};
	},
});
